import axios from 'axios'

class OrganizationsService {
    list() {
        return axios.get('/api/ivy-organizations/v1/organizations/', {}, {
            withCredentials: true
        })
    }
    set(context) {
        return axios.post('/api/ivy-organizations/v1/context/', {
            organization: context.organization,
            domain: context.domain
        }, {
            withCredentials: true
        })
    }
    get() {
        return axios.get('/api/ivy-organizations/v1/context/', {},{
            withCredentials: true
        })
    }
}

const organizationsService = new OrganizationsService()

export default organizationsService