import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'

// import en from './locales/en.json'
import fr from './locales/fr.json'

export const SUPPORT_LOCALES = ['en', 'fr']

export function setupI18n(
	options = {
		legacy: false,
		locale: 'fr',
		fallbackLocale: 'fr',
		messages: {
			fr
		}
	}) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  loadLocaleMessages(i18n, options.locale)

  window.addEventListener("ivy-switchLanguage", async function (newLanguage) {
    await loadLocaleMessages(i18n, newLanguage.detail.code);
    setI18nLanguage(i18n, newLanguage.detail.code);
  });

  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}


// const i18n = createI18n({
// 	locale: 'fr',
// 	fallbackLocale: 'en',
// 	messages,
// })
