<template>
    <div v-if="isLogged" class="drop-shadow-lg min-w-full p-3 border-solid bg-custom_organization-50 flex place-content-between" :class="{'swing-out-top-bck': isLogout}">
        <div class="flex items-center space-x-3">
            <a class="cursor-pointer" v-on:click="singleSpaNavigate('HUB_PAGE')">
                <h2 class="dark:text-custom_organization-950 text-custom_organization-950 font-bold text-2xl">Ivy</h2>
            </a>
            <a class="rounded-xl hover:bg-custom_organization-100 py-2 px-3 cursor-pointer" v-on:click="singleSpaNavigate('ITEMS_PAGE')" v-if="isAdmin">{{ $t("Items") }}</a>
            <!-- <a class="rounded-xl hover:bg-custom_organization-100 py-2 px-3" :href="configuratorPath">{{ $t('Configurator')}}</a> -->
            <a class="rounded-xl hover:bg-custom_organization-100 py-2 px-3 cursor-pointer" v-on:click="singleSpaNavigate('CONFIGURATOR_PAGE')">{{ $t('Configurator')}}</a>
            <a class="rounded-xl hover:bg-custom_organization-100  py-2 px-3 cursor-pointer" v-on:click="singleSpaNavigate('ADMIN_USERS')" v-if="isAdmin">{{$t('Users')}}</a>
        </div>
        <div class="flex items-center space-x-3">
            <!-- <a class="rounded-xl hover:bg-custom_organization-200  py-2 px-3" :href="adminUsersPath" v-if="isAdmin">{{connectedUser?.user?.firstname}}</a> -->

            <LangSwitcher></LangSwitcher>

            <div class="dropdown-user">
                <span class="dropdown-btn-user rounded-xl hover:bg-custom_organization-100 inline-flex gap-1 items-center py-2 px-3 cursor-default">
                    <i class="gg-profile"></i>
                    {{connectedUser?.user?.firstname}}
                </span>

                <!-- Dropdown menu -->
                <div class="dropdown-content-user hidden absolute bg-custom_organization-50 rounded-lg shadow-xl min-w-[150px] w-fit top-[50px]">
                    <ul class="y-2 dark:text-gray-200">
                        <!-- <li class="block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex">
                            <span>Paramètres</span>
                        </li> -->
                        <!-- <li class="block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex">
                            <span>Mon profil</span>
                        </li> -->
                        <li class="rounded-lg block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex gap-2 items-center cursor-pointer">
                            <i class="gg-pen text-zinc-400"></i><span class="text-zinc-400">{{ $t('My profile')}}</span>
                        </li>
                        <li class="rounded-lg block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex gap-2 items-center cursor-pointer" @click="logout">
                            <i class="gg-log-off text-red-500"></i><span class="text-red-500">{{ $t('logout')}}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="dropdown-domain">
                <span class="dropdown-btn-domain rounded-xl hover:bg-custom_organization-100 inline-flex gap-1 items-center py-2 px-3 cursor-default">
                    <i class="gg-briefcase"></i>
                    {{context?.organization}}
                </span>

                <!-- Dropdown menu -->
                <div class="dropdown-content-domain hidden absolute bg-custom_organization-50 rounded-lg shadow-xl min-w-[150px] w-fit top-[50px] right-[10px]">
                    <ul class="y-2">
                        <div v-for="organization in organizations" v-bind:key="organization.id"
                            class="text-zinc-700 brounded-lg flex flex-col px-4 py-2 gap-2 border-b-2">
                            {{organization.label}}
                            <li v-for="domain in organization.domains"  v-bind:key="domain" @click="changeContext({'organization' : organization.id, 'domain': domain})"
                                class="rounded-lg block px-4 py-2 hover:bg-custom_organization-100 cursor-pointer ml-2">
                                {{ domain }}
                            </li>
                        </div>
                        <!-- <li class="block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex">
                            <span>Paramètres</span>
                        </li> -->
                        <!-- <li class="block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex">
                            <span>Mon profil</span>
                        </li> -->
                        <li class="rounded-lg block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex gap-2 items-center cursor-pointer">
                            <i class="gg-pen text-zinc-400"></i><span class="text-zinc-400">{{ $t('Edit my enterprises')}}</span>
                        </li>
                        <li class="rounded-lg block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex gap-2 items-center cursor-pointer">
                            <i class="gg-add-r text-zinc-400"></i><span class="text-zinc-400">{{ $t('Add a enterprise')}}</span>
                        </li>
                    </ul>
                </div>
            </div>


            <!-- <button class="rounded-full bg-custom_organization-800 text-light py-2 px-3" @click="logout">Logout</button> -->
        </div>
    </div>
</template>

<script>
import OrganizationsService from '@/services/organizations-service';
import AuthService from '@ivy/auth-service'
import RouterService from '@ivy/router-service'

import LangSwitcher from './LangSwitcher.vue'

export default {
    name: 'NavBar',
    components: {
        LangSwitcher
    },
    data() {
        return {
            isLogout: false,
            connectedUser: null,
            context: null,
            openDropdownUser: false,
        }
    },
    methods: {
        logout() {
            AuthService.logout()
                .then(() => {
                    this.isLogout = true;
                    setTimeout(() => {
                        RouterService.on('LOGOUT')
                    }, 700);
                })
        },
        changeContext(organization) {
            OrganizationsService.set(organization)
            .then(() => {
                RouterService.on('CONTEXT_SET')
            })
        },
        singleSpaNavigate(path) {
            RouterService.goTo(path);
        }
    },
    async mounted() {
        if(!AuthService.isLogged() && !RouterService.isOn('LOGIN_PAGE')) {
            RouterService.on('LOGOUT')
        }

        try{
            if(AuthService.isLogged()) {
                var user = AuthService.getJwt();
                this.connectedUser = user;
            }

            var response = await OrganizationsService.list();
            this.organizations = response.data;

            response = await OrganizationsService.get();
            this.context = response.data;

            if(!this.context.organization && this.organizations.length > 0) {
                for(var i=0; i<this.organizations.length; i++) {
                    if(this.organizations[i].domains.length > 0) {
                        var context = {
                            'organization': this.organizations[i].id,
                            'domain': this.organizations[i].domains[0],
                        }
                        await OrganizationsService.set(context);
                        RouterService.on('CONTEXT_SET');
                        break;
                    }
                }
            }
        } catch(e) {
            console.log(e)
        }

        // Set the color according to the organization preferences :
        // tailwind.config.theme.extend.colors.custom[700] = '#0369a1'
    },
    computed: {
        isAdmin() {
            return AuthService.isAdmin()
        },
        isLogged() {
            return AuthService.isLogged()
        }
    }
}
</script>

<style scoped>
* {
    --ggs: 0.8
}

.dropdown-domain:hover .dropdown-content-domain,
.dropdown-user:hover .dropdown-content-user {
    display: block;
}

.swing-out-top-bck {
	-webkit-animation: swing-out-top-bck 0.65s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
	animation: swing-out-top-bck 0.65s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}
@-webkit-keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}
@keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}
</style>