<template>
  <div class="dropdown-langSwitcher">
    <span
      class="dropdown-btn-langSwitcher rounded-xl hover:bg-custom_organization-100 inline-flex gap-1 items-center py-2 px-3 cursor-default">
      {{ currentLang.flag }}
      {{ currentLang.label }}
    </span>

    <!-- Dropdown menu -->
    <div
      class="dropdown-content-langSwitcher hidden absolute bg-custom_organization-50 rounded-lg shadow-xl min-w-[150px] w-fit top-[50px]">
      <ul class="y-2">
        <li v-for="lang in availableLangs" v-bind:key="lang.code"
          class="rounded-lg block px-4 py-2 hover:bg-custom_organization-100 dark:hover:bg-gray-600 dark:hover:text-white flex gap-2 items-center cursor-pointer"
          @click="changeLang(lang)">
          <span class="text-zinc-900">{{ lang.flag + ' ' + lang.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LangSwitcher',
  data() {
    return {
      currentLang: {
        label: 'FR',
        code: 'fr',
        flag: '🇫🇷'
      },
      availableLangs: [
        {
          label: 'FR',
          code: 'fr',
          flag: '🇫🇷'
        },
        {
          label: 'EN',
          code: 'en',
          flag: '🇬🇧'
        }
      ],
    }
  },
  methods: {
    changeLang(newLang) {
      var event = new CustomEvent("ivy-switchLanguage", { detail: newLang });
      window.dispatchEvent(event);
      this.currentLang = newLang;
    },
  },
}
</script>

<style scoped>
.dropdown-langSwitcher:hover .dropdown-content-langSwitcher {
    display: block;
}

</style>